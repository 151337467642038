@import "../node_modules/bootstrap/scss/bootstrap";
//variables
$black: #111D2C;
$darkblue: #111D2D;
$red: #c1272d;
$white: #ffffff;
$backgroundGray: #F2F3F4;
$containerGray:#E6E7E9;
$outlineGray: #bcbdc0;
$yellow: #F3E063;
$royalBlue: #0C4499;
$skyBlue: #23BFE4;
$standardTransitionDelay: .5s;
$buttonHeight: 35px;
$buttonPadding: 5px 15px;

//fonts
//fonts
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

//styles
body {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 0;
    min-width: 100%;
    background-color: $backgroundGray;

    #root {

        header.global {
            border-color: $red !important;
            background-attachment: scroll;
            background-clip: border-box;
            background-color: $darkblue;
            background-image: none;
            background-origin: padding-box;
            background-position-x: 0%;
            background-position-y: 0%;
            background-size: auto;
            color: $white;
            line-height: 24px;
            text-align: left;
            text-size-adjust: 100%;
            -webkit-tap-highlight-color: $white;
         
        }

        h1 {
            font-size: 42px;
            font-weight: 100;

            ::after {
                content: "";
                display: block;
                margin: 10px 0 0 0;
                width: 130px;
                height: 2px;
                background: $red;
            }
        }

        h2 {
            line-height: 38px;
            padding: 0 0 0 10px;
            border-left: 3px double $red;
            font-size: 26px;
            font-weight: 100;
            height: 38px;
            overflow-y: hidden;
        }

        h3 {
            font-size: 21px;
            font-weight: 700;
        }

        .linkBody {
            font-size: 15px;
            font-weight: 400;

            :hover {
                color: $red;
                transition-delay: $standardTransitionDelay;
            }
        }

        .buttonText {
            font-size: 16px;
            font-weight: 700;
        }

        .linkFooter {
            font-size: 14px;
            font-weight: 400;

            :hover {
                text-decoration: underline;
            }
        }

        .blueGradient {
            background: $skyBlue;
            background: linear-gradient(90deg, $skyBlue 0%, $royalBlue 50%, $skyBlue 100%);
        }

        .primaryButton {
            height: $buttonHeight;
            padding: $buttonPadding;
            color: $white;
            background-color: $royalBlue;

            :hover {
                background-color: $black;
                transition-delay: $standardTransitionDelay;
            }
        }

        .secondaryButton {
            height: $buttonHeight;
            padding: $buttonPadding;
            color: $royalBlue;
            background-color: $white;
            border: 1px solid $royalBlue;

            :hover {
                color: $white;
                background-color: $royalBlue;
                transition-delay: $standardTransitionDelay;
            }
        }

        .navbaritem {
            background-color: $royalBlue;
            color: $white;
            height: $buttonHeight;
            padding: 0px 0px;

            :hover {
                background-color: $black;
                transition-delay: $standardTransitionDelay;
            }

            .selected {
                border-bottom: 3px solid $white;
            }
        }

        img.bottom {
            max-height: 75px;
        }



        footer.global {
            border-color: $red !important;
            background-attachment: scroll;
            background-clip: border-box;
            background-color: $darkblue;
            background-image: none;
            background-origin: padding-box;
            background-position-x: 0%;
            background-position-y: 0%;
            background-size: auto;
            box-sizing: border-box;
            color: $white;
            display: block;
            height: 100px;
            left: 0px;
            line-height: 24px;
            margin-bottom: -24px;
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 32px;
            text-size-adjust: 100%;
            -webkit-tap-highlight-color: $black;
        }
    }
}