.childColumn{
    margin-left:auto;
    margin-right: auto;
    display:block;
    text-align: center;
    width: 100%;
}

.centeredRow{
    width:100%;
}